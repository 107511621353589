import * as React from "react"
import { Link } from "gatsby"

export const Footer = () => {
  return (
    <footer className="footerContainer py-5 text-white">
      <div className="container">
        <Link to="/">
          <img 
            src="/images/footer_logo.svg"
            alt="トナリノ"
            width={100}
            height={22}
            placeholder="none"
            className="mb-0"
          />
        </Link>
        <div className="d-md-flex justify-content-between mt-3">
          <p className="copyright mb-0">©{` `}TONARINO All rights reserved.</p>
          <ul className="d-flex align-items-center mt-2 ms-0 mt-md-0">
            <li className="list-unstyled"><Link to="/privacy">プライバシーポリシー</Link></li>
            <li className="list-unstyled ps-2"><Link to="/tokutei">特定商取引法に基づく表示</Link></li>
            <li className="list-unstyled ps-3">
              <Link to="https://note.com/tonarino_jp" target="_blank">
                <img
                  src="/images/icon_footer_note.svg"
                  alt="note"
                  width={24}
                  height={24}
                  placeholder="none"
                  className="mb-0"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
