import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Nav } from "./headerParts/Nav"

const Header = ({ siteTitle }) => {
  const headerRef = React.useRef(null)
  React.useEffect(() => {
    if (window) {
      window.addEventListener("scroll", function () {
        if (headerRef.current) {
          const top =
            document.body.scrollTop > 71 ||
            this.document.documentElement.scrollTop > 71
              ? "-71px"
              : "0"
          headerRef.current.style.top = top
        }
      })
    }
  }, [])

  return (
    <header
      ref={headerRef}
      id="navbar"
      className="header container-fluid pt-3 fixed-top"
    >
      <div className="d-flex justify-content-between">
        <h1 className="mb-2">
          <Link to="/" className="logo" style={{ textDecoration: `none` }}>
            <img
              alt="トナリノ"
              src="/images/logo.svg"
              width={164}
              height={33}
              placeholder="none"
            />
          </Link>
        </h1>

        <Nav />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
