import { Link } from "gatsby"
import * as React from "react"

export const Nav = () => {
  const headerNavigationRef = React.useRef(null)
  const [navigationOpen, setNavigationOpen] = React.useState(false)
  const handleMenuIconClick = () => {
    const width = navigationOpen ? "0" : "250px"
    if (headerNavigationRef.current) {
      headerNavigationRef.current.style.width = width
    }
    setNavigationOpen(old => !old)
  }

  const menuIconClass = `menuIcon${navigationOpen ? " active" : ""}`

  return (
    <nav className="headerNavigationContainer me-md-3">
      <ul ref={headerNavigationRef} className="headerNavigation active">
        <li className="navigationMenuItem">
          <Link to="/about" className="p-2">
            トナリノとは？
          </Link>
        </li>
        <li className="navigationMenuItem">
          <Link to="/service" className="p-2">
            できること
          </Link>
        </li>
        <li className="navigationMenuItem">
          <Link to="/plans" className="p-2">
            料金プラン・申込
          </Link>
        </li>
        <li className="navigationMenuItem">
          <Link to="/philosophy" className="p-2">
            サービスへの思い
          </Link>
        </li>
        <li className="navigationMenuItem">
          <Link to="https://tayori.com/form/bce4102be00442293ae9c3fab28640c8eef48ea5/" className="p-2">
            お問い合わせ
          </Link>
        </li>
        <li className="navigationMenuItem">
          <Link to="https://note.com/tonarino_jp" className="p-2" target="_blank">
            トナリノnote
          </Link>
        </li>
      </ul>
      <button className={menuIconClass} onClick={handleMenuIconClick}>
        <div className="menuIconBox">
          <span className="menuIconLine menuIconLineTop"></span>
          <span className="menuIconLine menuIconLineMiddle"></span>
          <span className="menuIconLine menuIconLineBottom"></span>
        </div>
      </button>
    </nav>
  )
}
